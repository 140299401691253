<script lang="ts">
  import Cta from "$lib/ui/system/Cta.svelte";
  import { user } from "$lib/user";
  import LogoBoxes3DLazy from "./LogoBoxes3DLazy.svelte";
</script>

<div class="mt-24 sm:mt-32">
  <div class="hidden sm:block h-56">
    <LogoBoxes3DLazy />
  </div>

  <h2 class="sm:my-8 text-center type-title-lg">
    Ship your first app in minutes
  </h2>

  <div class="mt-8 sm:mt-12 flex justify-center">
    <div
      class="flex flex-col sm:flex-row items-center sm:border border-white/10 sm:bg-black/20 rounded-2xl"
    >
      <div class="sm:-ml-[1px] sm:-my-[1px]">
        <Cta primary large href={$user?.logged_in ? "/home" : "/signup"}
          >Get started</Cta
        >
      </div>
      <p class="mt-6 sm:mt-0 pl-4 pr-6 text-white/60">
        with $30 / month free compute
      </p>
    </div>
  </div>
</div>
